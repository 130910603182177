.navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    top: 0;
    left: 0;
    height: 90px;
    background-color: #fff;
    width: 100%;
    padding: 0 48px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;

    @media screen and (max-width: 992px) {
        padding: 0 16px;
        height: 60px;
        box-shadow: none;
    }

    &__collapse {
        height: calc(100vh - 124px);
        overflow-y: scroll;
        display: grid;
        grid-template-columns: 1fr;
        padding: 32px 0;
        top: 60px;
        left: 0;
        position: absolute;
        width: 100%;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;

        & a {
            text-decoration: none;
        }

        &-link {
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &-item {
            font-size: 20px;
            color: #606060;
            padding: 16px;
            border-bottom: 1px solid #dddddd;

            &--active {
                color: #fff;
                background-color: $color-primary;
            }
        }
    }

    &__logo {
        height: 80px;
        margin-top: 5px;
        @media screen and (max-width: 992px) {
            height: 50px;
        }
    }


    &__menu {
        display: flex;

        & a {
            text-decoration: none;
        }

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    &__toggle {
        display: none;
        align-items: center;
        color: $color-primary;

        > i {
            font-weight: 400;
        }

        @media screen and (max-width: 992px) {
            display: flex;
        }
    }

    &__item {
        box-sizing: border-box;
        display: flex;
        height: 90px;
        align-items: center;
        color: #606060;
        border-bottom: 3px solid #fff;
        cursor: pointer;
        margin-right: 24px;

        &:last-child {
            margin-right: 0px;
        }

        &--active {
            color: $color-primary;
            border-color: $color-primary;
        }
    }

    &__link {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: baseline;
        column-gap: 12px;
        font-size: 1.25rem;
        & a {
            color: #202020;
        }
    }

    &__button {
        font-size: .9rem;
        border: 1px solid #dddddd;
        color: #bcbcbc;
        border-radius: 40px;
        padding: 4px 8px;
        display: grid;
        grid-template-columns: repeat(4, auto);
        align-items: baseline;
        column-gap: 6px;
        &--active {
            color: $color-primary;
        }
        &--borderless {
            border: unset;
        }
    }


}