.layout {
    width: 100%;
    box-sizing: border-box;
    background-size: 100% 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 992px) {
        padding: 32px 16px;
        align-items: flex-start;
    }

    &--between {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 992px) {
            display: grid;
            row-gap: 24px;
            grid-template-columns: 1fr;
        }
    }

    &--around {
        display: flex;
        justify-content: space-around;
    }

    &--half {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 64px;
        align-items: flex-start;
        @media screen and (max-width: 992px) {
            row-gap: 48px;
            grid-template-columns: 1fr;
        }
    }

    &--center {
        display: flex;
        justify-content: center;
    }

    &__about {
        display: flex;
        justify-content: space-between;
    }
}