.footer {
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &--mobile {
        padding: 16px 0;
        justify-items: center;
        display: none;
        grid-template-columns: 1fr;
        row-gap: 16px;

        @media screen and (max-width: 768px) {
            display: grid;
        }
    }

    >* {
        flex: 1;
    }

    &__logo {
        height: 76px;
    }

    &__copy-right {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #bcbcbc;
    }

    &__link {
        color: #9F9F9F;
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 32px;
        align-self: center;
        justify-content: flex-end;

        & li {
            font-size: 14px;
            text-decoration: underline;
        }
    }

    &__social {
        color: #202020;
        & a {
            color: #202020;
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
        align-self: center;
        font-size: 20px;

        @media screen and (max-width: 768px) {
            grid-template-columns: auto auto 1fr;
            align-items: center;
        }
    }
}