$color-primary: #6bbc44;

@import "./navbar.scss";
@import "./footer.scss";
@import "./layout.scss";
@import "./font-awesome/light.scss";
@import "./font-awesome/solid.scss";
@import "./font-awesome/regular.scss";
@import "./font-awesome/duotone.scss";
@import "./font-awesome/brands.scss";
@import "./font-awesome/fontawesome.scss";
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    font-weight: 300;
    color: #202020;
    margin: 0;
    font-family: "Kanit", sans-serif;
}

strong {
    font-weight: 400;
}

a {
    color: $color-primary;
}

.app {
    margin-top: 90px;

    @media screen and (max-width: 992px) {
        margin-top: 60px;
    }
}

.container {
    box-sizing: border-box;
    margin: 0 auto;

    @media screen and (max-width: 992px) {
        width: 100%;
    }

    @media (min-width: 992px) {
        width: 960px;
    }

    @media (min-width: 1200px) {
        width: 1140px;
    }
}

.title {
    font-size: 2rem;
    font-weight: 400;

    @media (max-width: 992px) {
        line-height: 1;
        font-size: 1.5rem;
    }

    &--large {
        font-size: 50px;
        font-weight: 400;
    }

    &--primary {
        color: $color-primary;
    }
}

.link {
    font-size: 14px;
    display: flex;
    align-items: baseline;
    color: $color-primary;
    cursor: pointer;

    >i {
        margin-right: 8px;
    }

    >div {
        text-decoration: underline;
    }

}

.badge {
    width: 80px;
    height: 80px;
    color: #fff;
    background-color: #E82721;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 500;

    @media screen and (max-width: 992px) {
        font-size: 36px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        font-size: 24px;
    }
}

.button {
    cursor: pointer;
    font-family: "Kanit", sans-serif;
    width: 200px;
    height: 60px;
    border-radius: 30px;
    color: #fff;
    font-size: 20px;
    background-color: $color-primary;
    border: 1px solid $color-primary;
}

.quote {
    width: 32px;
    height: 1px;
    background-color: $color-primary;
    margin-right: 8px;
    display: inline-flex;
    vertical-align: middle;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: $color-primary;

    &--red {
        background-color: #E82721;
    }

    &--bold {
        height: 3px;
    }
}

.meta {
    font-size: 20px;
    font-weight: 400;
}

.content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-content: flex-start;
    justify-items: flex-start;
    row-gap: 16px;
}

.banner {
    &__content {
        box-sizing: border-box;
        position: absolute;
        width: 100%;

        @media screen and (max-width: 992px) {
            padding: 16px;
        }
    }

    &__box {
        position: relative;
    }

    &__title {
        max-width: 420px;
        border-bottom: 3px solid #E82721;
        padding-bottom: 16px;
        font-weight: 600;
        font-size: 48px;
        line-height: 1.3;

        @media screen and (max-width: 992px) {
            max-width: unset;
            font-size: 32px;
        }
    }

    &__image {
        width: 100%;

        @media screen and (max-width: 576px) {
            display: none;
        }

        &--mobile {
            width: 100%;
            display: none;

            @media screen and (max-width: 576px) {
                display: block;
            }
        }
    }

    &.layout {
        padding: 0;
    }
}

.about-established {
    &.layout {
        padding: 64px 0;
        height: auto;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }
}


.about {
    &.layout {
        @media screen and (max-width: 992px) {
            padding: 0;
            height: auto;
            background-color: rgb(242, 242, 242);
            background-size: contain;
            background-image: none;
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    &__box {
        width: 100%;
        position: relative;
        display: block;
    }

    & .content {
        @media screen and (max-width: 992px) {
            padding: 32px 16px;
        }
    }

    &__image {
        display: block;
        width: 100%;

        @media screen and (max-width: 992px) {
            display: none;
        }

        &--mobile {
            width: 100%;
            display: none;

            @media screen and (max-width: 992px) {
                display: block;
            }
        }
    }

    &__content {
        box-sizing: border-box;
        position: absolute;
        display: grid;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        grid-template-columns: 1fr 1fr;
        font-size: 20px;
        row-gap: 24px;
        align-items: center;
        justify-items: center;

        >div {
            width: 240px;

            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 992px) {
            font-size: 16px;
            grid-template-columns: 1fr;
            position: relative;
            padding: 32px 16px;
        }
    }
}

.property-minor {
    &.layout {
        height: auto;
        padding: 64px 0;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }

    &__image {
        @media screen and (max-width: 992px) {
            display: none;
        }

        &--mobile {
            display: none;

            @media screen and (max-width: 992px) {
                display: block;
            }
        }
    }

}

.property {
    &.layout {
        padding: 64px 0;
        height: auto;
    
        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }

    &__detail {
        color: $color-primary;
        font-size: 14px;
        font-weight: 400;
    }

    &__detail-content {
        font-size: 14px;
    }

    &__document {
        &-a {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 24px;
            column-gap: 24px;

            @media screen and (max-width: 992px) {}
        }

        &-d {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 24px;
            row-gap: 24px;

            @media screen and (max-width: 992px) {
                grid-template-columns: 1fr 1fr;
            }
        }

        >img {
            width: 120px;

            @media screen and (max-width: 992px) {
                width: 100%;
            }
        }
    }

    & .title {
        margin-bottom: 32px;
    }

    &__slider {
        display: none;
        margin-bottom: 32px;

        @media screen and (max-width: 992px) {
            display: block;
        }
    }

    &__navbar {
        display: flex;
        align-items: center;
        height: 240px;

        @media screen and (max-width: 992px) {
            height: auto;
        }

        & .title {
            @media screen and (max-width: 992px) {
                font-size: 20px;
            }
        }

        >img {
            width: auto;
            height: 96px;

            @media screen and (max-width: 992px) {
                width: auto;
                height: 64px;
            }
        }
    }

    &__menu {
        display: flex;
        // height: 90px;

        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
            flex-direction: column;
            overflow-x: auto;
            padding-left: 16px;
            display: none;
        }

        &--mobile {
            display: none;

            @media screen and (max-width: 992px) {
                display: block;
            }

            & .property__menu-item {
                text-align: center;
                height: auto;
                padding: 16px;
            }
        }
    }

    &__menu-item {

        line-height: 1.25;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // height: 90px;
        padding-bottom: 24px;
        justify-content: flex-start;
        color: #606060;
        border-bottom: 3px solid #fff;
        cursor: pointer;
        margin-right: 32px;

        @media screen and (max-width: 992px) {
            // min-width:300px;
        }

        &:last-child {
            margin-right: 0px;
        }

        &--active {
            color: $color-primary;
            border-color: $color-primary;
        }
    }

    &__divider {
        width: 1px;
        height: 96px;
        background-color: #dddddd;
        margin: 0 32px;

        @media screen and (max-width: 992px) {
            height: 64px;
        }
    }

    &__header {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: baseline;
        column-gap: 16px;

    }

    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 24px;

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    &__item {
        // width: 270px;
        box-sizing: border-box;
        height: 360px;
        background-color: #fff;
        box-shadow: 0px 20px 24px #D8E5CF;
        border-radius: 20px;
        padding: 16px;

        &--en {
            height: 420px;
        }

        @media screen and (max-width: 992px) {
            box-shadow: none;
        }
    }

    &__content {
        text-align: center;
    }

    &__number {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-primary;
        border: 1px solid $color-primary;
    }

    &__image {
        display: unset !important;
        width: 140px;
        height: 140px;
        border-radius: 70px;
        margin: 24px 0;
    }

    &__text {
        color: $color-primary;
        font-size: 1.15rem;
        font-weight: 400;
    }
}

.other-property {
    &.layout {
        padding: 64px 0;
        height: auto;
        background-color: #ECEEE1;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }

    & img {
        width: 650px;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }

    &__item {
        position: relative;
        width: unset !important;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        color: $color-primary;

        @media screen and (max-width: 992px) {
            font-size: 20px;
        }
    }


    &__card {
        box-sizing: border-box;
        width: 440px;
        height: 340px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -360px;

        @media screen and (max-width: 992px) {
            margin-top: -42px;
            position: relative;
            top: unset;
            right: unset;
            transform: unset;
            width: 100%;
            height: 100%;
            padding: 16px;
        }
    }

    &__content {

        padding: 0 48px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 24px;
        align-content: center;
        align-items: center;
        background-color: #fff;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 992px) {
            padding: 16px;
        }
    }
}

.instruction {
    &.layout {
        padding: 64px 0;
        height: 100%;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }

    & .content {
        justify-items: center;
    }

    & .title {
        text-align: center;
        margin-bottom: 32px;
    }

    &__list {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 48px;
        text-align: center;
        row-gap: 16px;

        @media screen and (max-width: 992px) {
            display: none;
        }
    }

    & img {
        width: 300px;
        height: 300px;

        @media screen and (max-width: 992px) {
            width: 100%;
            height: auto;
        }

    }

    &__content {
        justify-items: center;
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: $color-primary;
        border: 1px solid $color-primary;
        border-radius: 15px;
        font-weight: 400;
        font-size: 24px;
        background-color: #ECEEE1;
        justify-self: center;
    }


    &__title {
        font-size: 24px;
        font-weight: 400;
        color: $color-primary;
    }

    &__meta {
        font-size: 20px;
        font-weight: 400;
    }

    &__description {
        color: #606060;
    }

    &__line {
        position: absolute;
        top: 15px;
        width: 70%;
        height: 1px;
        background-color: $color-primary;
        left: 50%;
        transform: translateX(-50%);
    }

    &__slider {
        display: none;

        @media screen and (max-width: 992px) {
            display: block;
        }
    }
}

.contact {

    &__box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;

        @media screen and (max-width: 992px) {
            position: relative;
        }
    }

    &.layout {
        @media screen and (max-width: 992px) {
            display: grid;
            grid-template-columns: 1fr;
            height: auto;
            padding: 0;
            background-color: #EDF1F0;
            background-size: contain;
            background-image: none;
        }
    }

    & .content {
        @media screen and (max-width: 992px) {
            padding: 32px 16px;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
    }

    &__content {
        display: flex;
        align-items: baseline;

        >i {
            color: $color-primary;
            margin-right: 16px;
        }
    }

    &__image {
        width: 100%;
        display: block;

        @media screen and (max-width: 992px) {
            display: none;
        }

        &--mobile {
            width: 100%;
            display: none;

            @media screen and (max-width: 992px) {
                display: block;
            }
        }
    }
}

.qa {
    &.layout {
        padding: 64px 0;
        height: auto;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }

    &__header {
        display: grid;
        grid-template-columns: 8px 1fr auto;
        column-gap: 16px;
        align-items: baseline;
        padding: 16px 0;
    }

    &__content {
        color: #606060;
        padding: 18px 0;
        margin-left: 24px;
    }

    &__item {
        border-bottom: 1px solid #dddddd;
    }

    &__number {
        color: #C8C8C8;
    }

    &__title {
        font-size: 20px;
        font-weight: 400;
    }

    &__collapse {
        width: 30px;
        height: 30px;
        border: 1px solid #dddddd;
        color: $color-primary;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

.award {
    &.layout {
        padding: 64px 0;
        background-color: #ECEEE1;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
            height: auto;
        }
    }

    &__image {
        width: 100%;
        margin-top: 64px;

        @media screen and (max-width: 992px) {
            margin-top: 48px;
            width: 100%;
        }
    }
}

.location {
    &.layout {
        padding: 64px 0;
        background: transparent linear-gradient(180deg, #F2F2F2 0%, #FFFFFF 100%) 0% 0%;

        @media screen and (max-width: 992px) {
            padding: 32px 16px;
        }
    }

    & .content {
        justify-items: unset;
    }

    &.title {
        @media screen and (max-width: 992px) {
            text-align: center;
        }
    }

    &__item {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr 1fr;
        column-gap: 24px;
        padding: 16px 0;
        border-bottom: 1px solid #dddddd;

        >div {
            &:not(:first-child) {
                margin-left: 66px;
            }
        }

        @media screen and (max-width: 992px) {
            grid-template-columns: 1fr;
            row-gap: 8px;
        }

        &--active {
            background-color: #F2F2F2;
        }

        &--header {
            color: #9F9F9F;

            @media screen and (max-width: 992px) {
                display: none;
            }
        }

        &--content {
            font-size: 14px;
        }
    }

    &__icon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 25px;

        >img {
            width: 36px;
        }
    }

    &__name {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        column-gap: 16px;

        @media screen and (max-width: 992px) {
            font-weight: 400;
        }
    }

    &__search {
        box-sizing: border-box;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 260px;
        background-color: #fff;
        padding: 0 8px;
        font-size: 14px;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;

        @media screen and (max-width: 992px) {
            position: relative;
            top: unset;
            right: unset;
            width: 100%;
            box-shadow: unset;
        }


        &-header {
            text-align: center;
            padding: 12px 0;
            border-bottom: 1px solid #dddddd;
        }

        &-nearby {
            cursor: pointer;
        }

        &-content {
            display: grid;
            grid-template-columns: 1fr;
            padding: 12px 0;
            row-gap: 8px;

            >select {
                height: 32px;
                border-width: 0;
                border-bottom: 1px solid #dddddd;
            }

        }
    }

    &__input {
        position: relative;

        >input {
            box-sizing: border-box;
            height: 32px;
            border-width: 0;
            border-bottom: 1px solid #dddddd;
            width: 100%;
        }

        >i {
            position: absolute;
            right: 0;
            top: 8px;
        }
    }

    &__box {
        position: relative;
    }

    &__map {
        width: 100%;
        height: 600px;

        @media screen and (max-width: 992px) {
            height: 300px;
        }
    }

    &__navigation {
        text-align: center;
        color: rgb(65, 131, 215);
        text-decoration: none;

        >i {
            margin-bottom: 6px;
        }
    }

    &__detail {
        position: absolute;
        width: 260px;
        min-height: 130px;
        box-sizing: border-box;
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: #fff;
        padding: 16px;
        font-size: 14px;
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 16px;
        color: #202020;

        @media screen and (max-width: 992px) {
            top: unset;
            bottom: 160px;
            left: 10px;
        }

        &-title {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 6px;
        }

        &-description {
            line-height: 1.25;
        }
    }

    & .pagination {
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }
}


.pagination {
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 18px;

    >li {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px;

        &.active {
            background-color: #70AC47;
            color: #fff;
        }
    }

}

.social {
    &--line {
        height: 64px;
        width: 64px;
        z-index: 100;
        position: fixed;
        right: 24px;
        bottom: 24px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
        border-radius: 32px;

        >img {
            width: 100%;
        }
    }
}

.multi-line {
    text-align: left !important;
}

.__react_component_tooltip {
    max-width: 260px;
}

.site-layout-background {
    background-color: #fff;
    min-height: 360px;
    padding: 32px 16px;
}

.ant-layout-sider {
    background-color: #fff;
}

.logo {
    text-align: center;
}

.ant-form-item .ant-btn {
    margin-right: 8px;
}

.ant-upload {
    overflow-y: auto;
}

.content-text {
    white-space: pre-line;
}

ul {
    margin-top: auto;
    margin-bottom: auto;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    &__form {
        width: 320px;
    }
    &__title {
        font-size: 32px;
    }
    &__header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 32px;
    }
    & .ant-divider-vertical {
        height: 48px;
        margin: 0 16px;
    }
}

.covid-badge {  
    &--desktop {
        height: 96px;
        @media screen and (max-width: 992px) {
            display: none;
        }
    }
    &--mobile {
        height: 64px;
        display: none;
        @media screen and (max-width: 992px) {
            display: block;
        }
    }
}
